.NewMediaArt {
    position: relative;
    width: 100%;
    height: 55rem; // or whatever height you want for the canvas
    overflow: hidden;
    background-image: url('../../assets/wave.gif');
    background-repeat: no-repeat;
    background-size: cover; // This will cover the entire viewport
    background-position: center bottom; // This will center the image

    margin-top: 5rem;
    margin-bottom: 5rem;
    padding: 5rem 0 0 0;

    .square {
        position: absolute;
        border-radius: 15%;
        background-color: #423E24;
        transition: transform 0.3s ease;
        &:hover {
            transform: scale(2);
            cursor: pointer;
            z-index: 9999;
        }
    }

    
    @media (max-width: 768px) { // Tablet breakpoint
        padding: 0 0 0 0;
        height: 60rem;
    }

    @media (max-width: 480px) { // Mobile breakpoint
        padding: 0 0 0 0;
        height: 50rem;

        .square {
            // Adjust any styles specific for the square on mobiles
            border-radius: 20%;
        }
    }
}

